/* eslint-disable object-curly-newline */
<template>
  <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm">
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <calendar-left-sidebar-content @open-event-modal="showAddEventModal"></calendar-left-sidebar-content>
    </v-navigation-drawer>
    <div
      :style="contentStyles"
      class="h-full"
    >
      <div
        v-if="refCalendar"
        class="calendar-header mx-6 my-5 d-flex align-center flex-wrap"
      >
        <v-btn
          icon
          class="d-inline-block d-md-none me-1"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="30">
            {{ icons.mdiMenu }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-1"
          @click="refCalendar.prev()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-3"
          @click="refCalendar.next()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refCalendar.title }}
        </p>

        <v-spacer></v-spacer>

        <v-btn-toggle
          v-model="activeCalendarView"
          color="primary"
          class="mt-3 mt-sm-0"
        >
          <v-btn
            v-for="calendarViewOption in calendarViewOptions"
            :key="calendarViewOption"
            elevation="0"
            :value="calendarViewOption"
          >
            {{ calendarViewOption }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-calendar
        v-if="!isLoadingData"
        ref="refCalendar"
        v-model="calendarValue"
        :type="activeCalendarView"
        :events="calendarEvents"
        :event-margin-bottom="5"
        :event-text-color="getEventTextColor"
        @click:event="showEvent"
        @click:day="calendarHandleDayClick"
        @click:time="calendarHandleDayClick"
        @click:more="calendarHandleMoreClick"
      ></v-calendar>
      <v-progress-linear
        v-else
        :size="100"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card
          color="grey lighten-4"
          width="350px"
          flat
        >
          <v-toolbar
            :color="selectedEvent.color"
          >
            <div class="py-4">
              <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
              <small>{{ selectedEvent.start }} - {{ selectedEvent.end }}</small>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="selectedOpen = false"
            >
              <v-icon class="mr-1">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-badge
              dot
              left
              inline
              class="mb-4 pl-0 ml-0"
            >
              <small>
                {{ selectedEvent.type }}
              </small>
            </v-badge>
            <br>
            <span>{{ selectedEvent.description }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="showEditEventModal(selectedEvent)"
            >
              <v-icon class="mr-1">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              outlined
              @click="deleteConfirmationModal = true"
            >
              <v-icon>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
    <ModalDialog
      :visible="isEventModalActive"
      :width="600"
      :hidden-actions="false"
      :header="dialog === 'add'? 'Tambah Event': 'Edit Event'"
      @close-modal="isEventModalActive = false"
      @close="isEventModalActive = false"
    >
      <template v-slot:body>
        <calendar-event-modal-content
          :event.sync="event"
          :clear-event-data="clearEventData"
          :is-edit="isEdit"
          :is-loading-button.sync="isLoadingButton"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
          @close-drawer="isEventModalActive = false"
        ></calendar-event-modal-content>
      </template>
    </ModalDialog>
    <ConfirmDialog
      header="Hapus Event"
      :visible="deleteConfirmationModal"
      @close-button="deleteConfirmationModal = false"
      @destroy-button="removeEvent"
    >
    </ConfirmDialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onUnmounted, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { getVuetify } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiChevronRight, mdiClose, mdiMenu, mdiPencil, mdiTrashCan } from '@mdi/js'
import calendarStoreModule from './calendarStoreModule'

// Local Components
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import CalendarEventModalContent from './CalendarEventModalContent.vue'
import CalendarLeftSidebarContent from './CalendarLeftSidebarContent.vue'

export default {
  components: {
    CalendarLeftSidebarContent,
    CalendarEventModalContent,
    ModalDialog,
    ConfirmDialog,
  },
  setup(props, context) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    const isEventModalActive = ref(false)
    const isLoadingButton = ref(false)
    const dialog = ref('')
    const isLoadingData = ref(false)
    const selectedOpen = ref(false)
    const selectedElement = ref(null)
    const selectedEvent = ref({})
    const isEdit = ref(false)
    const deleteConfirmationModal = ref(false)
    const attrs = ref({
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    })

    // School Uuid
    const schoolUuid = JSON.parse(localStorage.getItem('user')).user.details[0].uuid

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    // ————————————————————————————————————
    //* ——— Vuetify Instance
    // ————————————————————————————————————

    const $vuetify = getVuetify()

    // ————————————————————————————————————
    //* ——— Left Sidebar
    // ————————————————————————————————————
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    // ————————————————————————————————————
    //* ——— Templare Ref
    // ————————————————————————————————————

    const refCalendar = ref(null)

    // ————————————————————————————————————
    //* ——— Calendar View/Type
    // ————————————————————————————————————

    const activeCalendarView = ref('month')
    const calendarViewOptions = ['month', 'week', 'day']

    // ————————————————————————————————————
    //* ——— Calendar Value & Events
    // ————————————————————————————————————

    const calendarValue = ref()
    const calendarEvents = ref([])

    const blankEvent = {
      uuid: '',
      title: '',
      event_start_date: '',
      event_end_date: '',
      timed: false,
      extendedProps: {
        calendar: '',
        guests: [],
        location: '',
        description: '',
      },
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }

    // Global services
    const services = context.root.$services.ApiServices

    const fetchEvents = async (params = {}) => {
      isLoadingData.value = true
      await services.list('calendar', { ...params }).then(
        ({ data }) => {
          calendarEvents.value = data.data.map(item => ({
            ...item,
            uuid: item.uuid,
            description: item.description,
            start: item.event_start_date,
            end: item.event_end_date,
            name: item.title,
            type: item.type,
            color: item.type.toLowerCase() === 'event' ? 'success' : 'info',
          }))
        },
        err => console.error(err),
      )
      isLoadingData.value = false
    }

    fetchEvents()

    const getEventTextColor = e => e.eventTextColor

    //
    //* ——— Calendar Event Handler ——————————————————
    //

    // TODO: Remove this once issue is resolved
    // Please check: https://github.com/vuetifyjs/vuetify/issues/13900
    const eventClickBugWorkaround = ref(false)

    const calenderHandleEventClick = eventObj => {
      // Grab only event object
      event.value = eventObj.event

      // eslint-disable-next-line no-use-before-define
      // isEventModalActive.value = true

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    const calendarHandleDayClick = dayObj => {
      // Bug workaround
      if (eventClickBugWorkaround.value) {
        eventClickBugWorkaround.value = false

        return
      }

      event.value.event_start_date = dayObj.date

      // eslint-disable-next-line no-use-before-define
      showAddEventModal()
    }

    const calendarHandleMoreClick = ({ date }) => {
      calendarValue.value = date

      activeCalendarView.value = 'day'

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    //
    //* ——— Event Actions ——————————————————
    //

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const showAddEventModal = () => {
      isEventModalActive.value = true
      dialog.value = 'add'

      isEdit.value = false
    }

    const addEvent = async eventData => {
      isLoadingButton.value = true
      // Only get date from event
      /* eslint-disable no-param-reassign */
      if (eventData.event_start_date) {
        eventData.event_start_date = new Date(eventData.event_start_date).toISOString().substr(0, 10)
      }
      if (eventData.event_end_date) {
        eventData.event_end_date = new Date(eventData.event_end_date).toISOString().substr(0, 10)
      }

      eventData.timed = false
      eventData.school_uuid = schoolUuid
      /* eslint-enable */

      await services.add('calendar', eventData).then(
        ({ data }) => {
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          fetchEvents()
        },
        err => console.error(err),
      )
      isEventModalActive.value = false
      isLoadingButton.value = false
    }

    const showEditEventModal = eventData => {
      event.value = eventData
      isEdit.value = true
      dialog.value = 'edit'

      isEventModalActive.value = true
    }

    const updateEvent = async eventData => {
      isLoadingButton.value = true
      eventData.school_uuid = schoolUuid
      await services.update('calendar', eventData, eventData.uuid).then(
        ({ data }) => {
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          fetchEvents()
        },
        err => console.error(err),
      )
      isEventModalActive.value = false
      isLoadingButton.value = false
    }

    const removeEvent = async () => {
      isLoadingButton.value = true
      const eventUuid = selectedEvent.value.uuid
      await services.destroy('calendar', eventUuid).then(
        ({ data }) => {
          showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          fetchEvents()
        },
        err => console.error(err),
      )
      selectedOpen.value = false
      isLoadingButton.value = false
      deleteConfirmationModal.value = false
    }

    const showEvent = ({ nativeEvent, event }) => {
      const open = () => {
        selectedEvent.value = event
        selectedElement.value = nativeEvent.target
        requestAnimationFrame(
          () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            requestAnimationFrame(() => {
              selectedOpen.value = true
            }),
          // eslint-disable-next-line function-paren-newline
        )
      }

      if (selectedOpen.value) {
        selectedOpen.value = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }

    //
    //* ——— Selected Calendars ——————————————————
    //
    const selectedCalendars = computed(() => store.state[CALENDAR_APP_STORE_MODULE_NAME].selectedCalendars)

    watch(selectedCalendars, () => {
      if (selectedCalendars.value.length < 1) {
        calendarEvents.value = []
      } else {
        const params = selectedCalendars.value.length === 1 ? { type: selectedCalendars.value[0] } : {}
        fetchEvents(params)
      }
    })

    // ————————————————————————————————————
    //* ——— Event Handler
    // ————————————————————————————————————

    watch(isEventModalActive, val => {
      clearEventData()
    })

    return {
      // Left Sidebar
      isLeftSidebarOpen,
      contentStyles,

      // Template Refs
      refCalendar,

      getEventTextColor,
      dialog,
      // Calendar View/Type
      activeCalendarView,
      calendarViewOptions,

      // Calendar Value & Events
      calendarValue,
      calendarEvents,
      event,
      clearEventData,

      calenderHandleEventClick,
      calendarHandleDayClick,
      calendarHandleMoreClick,

      showAddEventModal,
      addEvent,
      showEditEventModal,
      updateEvent,
      removeEvent,
      showEvent,
      isEdit,
      deleteConfirmationModal,

      // attribute
      attrs,

      // Event handler sidebar
      isEventModalActive,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
        mdiPencil,
        mdiTrashCan,
        mdiClose,
      },

      isLoadingButton,
      isLoadingData,
      selectedOpen,
      selectedElement,
      selectedEvent,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
</style>
