var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"refCalendarEventHandlerForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mb-6",attrs:{"label":"Name","placeholder":"Name","outlined":"","rules":[_vm.validators.required],"hide-details":"auto","dense":""},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}}),_c('v-select',{staticClass:"mb-6",attrs:{"outlined":"","label":"Calendar","placeholder":"Calendar","item-text":function (item) { return item.label; },"item-value":function (item) { return item.value; },"items":_vm.$store.state['app-calendar'].calendarOptions,"rules":[_vm.validators.required],"hide-details":"auto","menu-props":{ offsetY: true },"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"me-2",attrs:{"inline":"","color":item.color,"dot":""}}),_c('span',[_vm._v(_vm._s(item.label))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{staticClass:"me-2 mb-1",attrs:{"inline":"","color":item.color,"dot":""}}),_c('span',[_vm._v(_vm._s(item.label))])],1)]}}]),model:{value:(_vm.eventLocal.type),callback:function ($$v) {_vm.$set(_vm.eventLocal, "type", $$v)},expression:"eventLocal.type"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-6",attrs:{"label":"Start Date","readonly":"","outlined":"","dense":"","hide-details":"auto","rules":[_vm.validators.required]},model:{value:(_vm.eventLocal.event_start_date),callback:function ($$v) {_vm.$set(_vm.eventLocal, "event_start_date", $$v)},expression:"eventLocal.event_start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isStartDateMenuOpen),callback:function ($$v) {_vm.isStartDateMenuOpen=$$v},expression:"isStartDateMenuOpen"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isStartDateMenuOpen = false}},model:{value:(_vm.eventLocal.event_start_date),callback:function ($$v) {_vm.$set(_vm.eventLocal, "event_start_date", $$v)},expression:"eventLocal.event_start_date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-6",attrs:{"label":"End Date","readonly":"","outlined":"","dense":"","hide-details":"auto","rules":[_vm.validators.required, _vm.validators.endDateValidator]},model:{value:(_vm.eventLocal.event_end_date),callback:function ($$v) {_vm.$set(_vm.eventLocal, "event_end_date", $$v)},expression:"eventLocal.event_end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isEndDateMenuOpen),callback:function ($$v) {_vm.isEndDateMenuOpen=$$v},expression:"isEndDateMenuOpen"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isEndDateMenuOpen = false}},model:{value:(_vm.eventLocal.event_end_date),callback:function ($$v) {_vm.$set(_vm.eventLocal, "event_end_date", $$v)},expression:"eventLocal.event_end_date"}})],1),_c('v-textarea',{attrs:{"outlined":"","label":"Description","placeholder":"Description"},model:{value:(_vm.eventLocal.description),callback:function ($$v) {_vm.$set(_vm.eventLocal, "description", $$v)},expression:"eventLocal.description"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","type":"submit","large":"","loading":_vm.isLoadingButton,"disabled":!_vm.eventLocal.title || !_vm.eventLocal.type || !_vm.eventLocal.description || !_vm.eventLocal.event_start_date || !_vm.eventLocal.event_end_date}},[_vm._v(" "+_vm._s(_vm.eventLocal.uuid ? 'Update' : 'Tambah ')+" ")]),(!_vm.isEdit)?_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","large":"","color":"primary"},on:{"click":_vm.resetEventLocal}},[_vm._v(" Reset ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }