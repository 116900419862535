import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'info',
        label: 'Holiday',
      },
      {
        color: 'success',
        label: 'Event',
      },
    ],
    selectedCalendars: ['Holiday', 'Event'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_CALENDARS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents({ state }) {
      return new Promise((resolve, reject) => {
        const rnd = (a, b) => Math.floor((b - a + 1) * Math.random()) + a

        const events = []
        const start = '02/02/2022'
        const end = '31/02/2022'

        const min = new Date(`${start}`)
        const max = new Date(`${end}`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = rnd(days, days + 20)

        const colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
        const names = ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']

        for (let i = 0; i < eventCount; i += 1) {
          const allDay = rnd(0, 3) === 0
          const firstTimestamp = rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            title: names[rnd(0, names.length - 1)],
            event_start_date: first,
            event_end_date: second,
            color: colors[rnd(0, colors.length - 1)],
            timed: !allDay,
          })
        }
        resolve(events)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ error: 'error' })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
